import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { FaTelegram, FaWhatsapp } from 'react-icons/fa';
import MainComponent from './components/MainComponent';
import Navbar from './components/Navbar';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    background: linear-gradient(to bottom right, #e0f2fe, #bfdbfe);
    min-height: 100vh;
  }
`;

const AppLayout = styled.div`
 display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px); // Adjust for navbar height

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Sidebar = styled.div`
  background: white;
  padding: 20px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    width: 300px;
    border-radius: 0 10px 10px 0;
  }

  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

const MainContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const AnnouncementTitle = styled.h2`
  font-size: 1.1rem;
  color: #1e40af;
  margin-bottom: 1rem;
`;

const AnnouncementLink = styled.a`
  color: #3b82f6;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled.span`
  margin-right: 0.5rem;
  font-size: 1.2rem;
`;

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <AppLayout>
        <Sidebar>
          <AnnouncementTitle>Announcements</AnnouncementTitle>
          <AnnouncementLink href="https://t.me/Xstreamios" target="_blank" rel="noopener noreferrer">
            <Icon><FaTelegram /></Icon>
            @Xstreamios
          </AnnouncementLink>
          <AnnouncementLink href="https://t.me/ibrahim_dhillon" target="_blank" rel="noopener noreferrer">
            <Icon><FaTelegram /></Icon>
            @ibrahim_dhillon
          </AnnouncementLink>
          <AnnouncementLink href="https://wa.me/+923218586012" target="_blank" rel="noopener noreferrer">
            <Icon><FaWhatsapp /></Icon>
            +923218586012
          </AnnouncementLink>
        </Sidebar>
        <MainContentArea>
          <Routes>
            <Route path="/:uniqueNumber?" element={<MainComponent />} />
          </Routes>
        </MainContentArea>
      </AppLayout>
    </Router>
  );
}

export default App;

