import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const TimerDisplay = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  background: linear-gradient(to right, #3b82f6, #1e40af);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CountdownTimer = ({ apiUrl, parameter }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  useEffect(() => {
    const fetchTimeLeft = async () => {
      try {
        
        console.log('parameter',parameter)
        const response = await axios.get(`${apiUrl}${parameter}`);
        const validPeriod = response.data.data.validperiod; // Days
        const updatedAt = new Date(response.data.data.updatedAt); // Timestamp of last update

        // Calculate expiration date (validperiod days from updatedAt)
        const expirationDate = new Date(updatedAt);
        expirationDate.setDate(expirationDate.getDate() + validPeriod); // Add validperiod days

        // Calculate time left in seconds
        const currentTime = new Date();
        const timeRemaining = expirationDate - currentTime; // Time remaining in milliseconds
        setTimeLeft(timeRemaining > 0 ? timeRemaining / 1000 : 0); // Convert milliseconds to seconds
      } catch (error) {
        console.error('Error fetching time left:', error);
      }
    };

    fetchTimeLeft();
    const timer = setInterval(fetchTimeLeft, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [apiUrl, parameter]);

  useEffect(() => {
    if (timeLeft === null) return;

    const timer = setInterval(() => {
      setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  if (timeLeft === null) {
    return <TimerDisplay>Loading...</TimerDisplay>;
  }

  // Calculate days, hours, and minutes
  const days = Math.floor(timeLeft / (24 * 3600)); // 1 day = 24 hours * 3600 seconds
  const hours = Math.floor((timeLeft % (24 * 3600)) / 3600); // Remaining hours
  const minutes = Math.floor((timeLeft % 3600) / 60); // Remaining minutes

  return (
    <TimerDisplay>
      {days.toString().padStart(2, '0')}:
      {hours.toString().padStart(2, '0')}:
      {minutes.toString().padStart(2, '0')}
    </TimerDisplay>
  );
};

export default CountdownTimer;
