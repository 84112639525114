import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FaCopy, FaEnvelope, FaCode, FaCalendar } from 'react-icons/fa';
import CountdownTimer from './CountdownTimer';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
`;

const Card = styled.div`
    background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    border-radius: 0;
    margin-bottom: 1rem;
  }
`;

const Title = styled.h2`
  font-size: 1.1rem;
  color: #1e40af;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background: linear-gradient(to right, #3b82f6, #1e40af);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease;
  font-family: 'Open Sans', sans-serif;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background: #9ca3af;
    cursor: not-allowed;
  }
`;
const ResultContainer = styled.div`
  margin-top: 1rem;
  background-color: #f3f4f6;
  border-radius: 4px;
  padding: 1rem;
`;

const ResultItem = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Icon = styled.span`
  margin-right: 0.5rem;
  color: #3b82f6;
`;

export default function MainComponent() {
  const { uniqueNumber } = useParams();
  const [inputValue, setInputValue] = useState(uniqueNumber || '');
  const [dateValue, setDateValue] = useState('');
  const [apiResult, setApiResult] = useState('');
  const [last2Number, set2Digit] = useState('');
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setInputValue(uniqueNumber || '');
  
    if (uniqueNumber && typeof uniqueNumber === 'string') {
      const lastTwoDigits = uniqueNumber.slice(-2);
      set2Digit(lastTwoDigits);
    } else {
      set2Digit('');  // Handle the case when uniqueNumber is not valid
    }
  }, [uniqueNumber]);
  

  // useEffect(() => {
  //   const url = `https://smsbackend.netlify.app/.netlify/functions/api/rental-period/phone/${inputValue}`;
  //   axios.get(url)
  //     .then(response => {
  //       const phone = response.data.phone_number;
  //       if (phone.length > 2) {
  //         const lastTwoDigits = phone.slice(-2);
  //         set2Digit(lastTwoDigits);
  //       } else {
  //         set2Digit(null);
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching phone number:', error);
  //     });
  // }, [inputValue]);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        alert('Code copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy code: ', error);
      });
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `https://smsbackend.netlify.app/.netlify/functions/api/sms-numbers/getmsg/${inputValue}`;
      // const corsProxyUrl = 'https://corsproxy.io/?';
      // const proxiedApiUrl = corsProxyUrl + apiUrl;
  
      const response = await axios.get(apiUrl);
  
      if (response.data.code === 10000) {
        // Handle success
        const message = response.data.data.message;
        setDateValue(new Date(response.data.data.time * 1000).toLocaleString());  // Convert timestamp to readable date
        setApiResult(message);
  
        // Extract the code from the message using regex
        const regex = /\b\d{5,6}\b/;
        const match = message.match(regex);
        if (match) {
          setCode(match[0]);
        }
      } else {
        // Handle errors based on the code
        console.error(`Error: ${response.data.code}`);
        setApiResult(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiResult('Error fetching data');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Container>
      <Card>
        <Title>Phone Number Information</Title>
        <p>The last two digits of the number: <strong>{last2Number ? `#${last2Number}` : "Number not available"}</strong></p>
        <p>Number Expires in:</p>
        <CountdownTimer apiUrl="https://smsbackend.netlify.app/.netlify/functions/api/sms-numbers/" parameter={inputValue} />
      </Card>

      <Card>
        <Title>Get Message</Title>
        <Input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter Number"
        />
        <Button onClick={handleButtonClick} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Get Message'}
        </Button>

        {apiResult && (
          <ResultContainer>
            <ResultItem>
              <Icon><FaEnvelope /></Icon>
              <strong>SMS:</strong> {apiResult}
            </ResultItem>
            {code && (
              <ResultItem>
                <Icon><FaCode /></Icon>
                <strong>Code:</strong> {code}
                <Icon as="span" onClick={copyCodeToClipboard} style={{ cursor: 'pointer', marginLeft: '0.5rem' }}>
                  <FaCopy />
                </Icon>
              </ResultItem>
            )}
            {dateValue && (
              <ResultItem>
                <Icon><FaCalendar /></Icon>
                <strong>Date:</strong> {dateValue}
              </ResultItem>
            )}
          </ResultContainer>
        )}
      </Card>
    </Container>
  );
}

